<template>
	<div>
		<div
			class="header-box-menu flex-row justify-space-between bg-white"
		>
			<input
				v-model="search.search_value"
				class="flex-3 pa-10 border-right"
				type="text"
				placeholder="검색어를 입력하세요"
				@keyup.enter="toProductList"
			/>

			<i
				class="pa-10 mdi mdi-magnify size-px-24 bg-base"
				@click="toProductList"
			></i>
		</div>
		<div
			class="text-center "
		>
			<div
				v-if="is_on_search"
				class=""
			>
				<div class="justify-space-between-in ">
					<div class="under-line pa-10 items-center">
						<div class="font-weight-700 size-px-13 flex-1 text-left">카테고리</div>
						<div class="flex-4 text-left">
							<select
								v-model="search.pdt_category"
								class="input-box pa-5-10 "
							>
								<option value="">카테고리</option>
								<option
									v-for="ca in category"
									:key="'category_' + ca.category_code"
									:value="ca.category_code"
								>{{ ca.category_name }}</option>
							</select>
						</div>
					</div>
					<div class="under-line pa-10 items-center">
						<div class="font-weight-700 size-px-13 flex-1 text-left">가격대</div>
						<div class="justify-start flex-row-wrap gap-10 flex-4">
							<label
								v-for="price in items_price"
								:key="'price_' + price.code"
								@click="search.price = price.code"
								class="text-left"
								style="width: 100px"
							>
								<v-icon
									v-if="search.price == price.code"
									small
									class="color-green"
								>mdi mdi-radiobox-marked</v-icon>
								<v-icon
									v-else
									small
								>mdi mdi-radiobox-blank</v-icon>
								{{ price.name }}
							</label>
						</div>
					</div>
					<div class="under-line pa-10 items-center">
						<div class="font-weight-700 size-px-13 flex-1 text-left">혜택</div>
						<div class="justify-start flex-row-wrap gap-10 flex-4">

							<label
								v-for="event in items_event"
								:key="'event_' + event.code"
								class="text-left"
								style="width: 100px"
							>
								<input
									v-show="false"
									v-model="search.event"
									type="checkbox"
									:value="event.code"
									/>
								<v-icon
									v-if="search.event.indexOf(event.code) > -1"
									small
									class="color-green"
								>mdi mdi-checkbox-marked</v-icon>
								<v-icon
									v-else
									small
								>mdi mdi-checkbox-blank-outline</v-icon>
								{{ event.name }}
							</label>
						</div>
					</div>
				</div>
			</div>
			<button
				v-if="is_product"
				class="btn-search"
				@click="is_on_search = !is_on_search"
			>상세 검색</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Search'
		,props: ['search', 'category', 'pdt_search']
		,components: {}
		,data: function(){
			return {
				is_on_search: false
				, items_price: []
				, items_event: []
				, is_product: false
			}
		}
		,methods:{
			toProductList: function(){

				if(this.$storage.setQuery(this.search)) {
					this.$emit('click')
				}
			}
		}
		, created() {

			this.items_price = this.pdt_search?.items_price
			this.items_event = this.pdt_search?.items_event

			if(this.$route.name == 'ProductList'){
				this.is_product = true
			}else{
				this.is_product = false
			}
		}
	}
</script>

<style>
	.btn-search {
		padding: 5px 10px;
		border-bottom: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-left: 1px solid #ddd;
		border-radius: 0 0 10px 10px;
		margin-bottom: 10px;
	}
</style>